import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1
      style={{
        fontFamily: 'georgia',
        color: 'pink',
        fontSize: '4rem',
      }}
    >
      Mr. Thomas
    </h1>
  </Layout>
)

export default IndexPage
